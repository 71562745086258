// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import Productdescription3 from "../../blocks/productdescription3/src/Productdescription3";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Languageswitch from "../../blocks/languageswitch/src/Languageswitch";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Elasticsearch2 from "../../blocks/elasticsearch2/src/Elasticsearch2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Customform from "../../blocks/customform/src/Customform";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Documentstorage from "../../blocks/documentstorage/src/Documentstorage";
import Cfgeneraldiscounting from "../../blocks/cfgeneraldiscounting/src/Cfgeneraldiscounting";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Automatedemailsending from "../../blocks/automatedemailsending/src/Automatedemailsending";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Download from "../../blocks/download/src/Download";
import Linkedaccounts from "../../blocks/linkedaccounts/src/Linkedaccounts";
import Itemquickviewimages from "../../blocks/itemquickviewimages/src/Itemquickviewimages";
import Cfapiintegrationwithtargeterp from "../../blocks/cfapiintegrationwithtargeterp/src/Cfapiintegrationwithtargeterp";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Sorting from "../../blocks/sorting/src/Sorting";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Wishlist2 from "../../blocks/wishlist2/src/Wishlist2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Cfbestsellerfeature from "../../blocks/cfbestsellerfeature/src/Cfbestsellerfeature";
import Cfusergroup from "../../blocks/cfusergroup/src/Cfusergroup";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Quotemanagement from "../../blocks/quotemanagement/src/Quotemanagement";
import Analytics from "../../blocks/analytics/src/Analytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfpricecalculation from "../../blocks/cfpricecalculation/src/Cfpricecalculation";



const routeMap = {
Customisableuserprofiles2:{
 component:Customisableuserprofiles2,
path:"/Customisableuserprofiles2"},
Productdescription3:{
 component:Productdescription3,
path:"/Productdescription3"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Languageswitch:{
 component:Languageswitch,
path:"/Languageswitch"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Elasticsearch2:{
 component:Elasticsearch2,
path:"/Elasticsearch2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Customform:{
 component:Customform,
path:"/Customform"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Documentstorage:{
 component:Documentstorage,
path:"/Documentstorage"},
Cfgeneraldiscounting:{
 component:Cfgeneraldiscounting,
path:"/Cfgeneraldiscounting"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Automatedemailsending:{
 component:Automatedemailsending,
path:"/Automatedemailsending"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Download:{
 component:Download,
path:"/Download"},
Linkedaccounts:{
 component:Linkedaccounts,
path:"/Linkedaccounts"},
Itemquickviewimages:{
 component:Itemquickviewimages,
path:"/Itemquickviewimages"},
Cfapiintegrationwithtargeterp:{
 component:Cfapiintegrationwithtargeterp,
path:"/Cfapiintegrationwithtargeterp"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Wishlist2:{
 component:Wishlist2,
path:"/Wishlist2"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Cfbestsellerfeature:{
 component:Cfbestsellerfeature,
path:"/Cfbestsellerfeature"},
Cfusergroup:{
 component:Cfusergroup,
path:"/Cfusergroup"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Quotemanagement:{
 component:Quotemanagement,
path:"/Quotemanagement"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cfpricecalculation:{
 component:Cfpricecalculation,
path:"/Cfpricecalculation"},

  Home: {
component:OrderManagement,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
